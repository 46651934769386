@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    body {
        @apply text-base leading-base text-neutral
    }
}

@layer components {
    .prose {
        @apply text-base leading-base text-neutral max-w-none
    }

    .prose > p > a {
        @apply font-normal text-base leading-base text-neutral
    }

    .prose a :where(img):not(:where([class~="not-prose"],[class~="not-prose"])) {
        @apply m-0 inline-block
    }
}

/* Google maps style required */
.pac-container {
    z-index: 1300;
    border: 1px solid #f0f0f0;
    background: #ffffff;
    box-shadow: none;
    
}

/* Tweak accordion on safari */
summary::-webkit-details-marker { 
    display: none; 
}

.product-detail-description-container a {
    display: inline-block;
}

.product-detail-description-container img {
    width: auto !important;
    max-width: 87px !important;
    float: none !important;
}

.product-detail-description-container .mgz-element-text .mgz-element-inner p:nth-child(2) img {
    max-width: 100% !important;
}
.product-detail-description-container .mgz-element-text .mgz-element-inner p:nth-child(1) img {
    max-width: 100% !important;
}

.product-detail-description-container .product-detail-description .row {
    display: flex;
    flex-wrap: wrap;
}

/* custom label */
.product-detail .plugin-product-detail-action .top-small img {
    max-width: 80px !important;
}
@media only screen and (max-width: 615px) {
    .product-detail .plugin-product-detail-action .top-small {
        right: 30px !important;
    }
}

.section-dialog-container .product-detail-description-container .mgz-element-inner {
    padding: 0 !important;
}